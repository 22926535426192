import * as React from "react"
import styled from "styled-components"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import {
  BlockRenderer,
  Layout,
  Project,
  Slides,
  Introduction,
  Illustration,
  IllustrationSlides,
} from "@components"
import { palette } from "@theme"
import { useActiveProjectDispatch } from "@utilities"
import { throttle } from "lodash"

interface PageProps {
  data: {
    sanityPage: GatsbyTypes.SanityPage
  }
}

const EditorialPage = ({ data }: PageProps) => {
  const {
    sanityPage: { projects, _rawDescription },
  } = data

  const dispatch = useActiveProjectDispatch()
  const handleScroll = React.useCallback(
    throttle(
      () =>
        dispatch({
          type: "setActiveProject",
          payload: {
            key: undefined,
          },
        }),
      1000
    ),
    []
  )

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <>
      <Helmet>
        <meta name="theme-color" content={palette.foggyGrey} />
      </Helmet>
      <Layout background={palette.balticSea}>
        <Introduction>
          <BlockRenderer>{_rawDescription}</BlockRenderer>
        </Introduction>
        <Grid>
          {projects?.map(row => {
            if (row?._type === "project")
              return (
                <Illustration
                  project={row as GatsbyTypes.SanityProject}
                  key={row!._key}
                />
              )
            else if (row?._type === "slides")
              return (
                <IllustrationSlides
                  twoColGrid
                  slides={row as GatsbyTypes.SanitySlides}
                  key={row!._key}
                />
              )
          })}
        </Grid>
      </Layout>
    </>
  )
}

const Grid = styled.main`
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  @media only screen and (min-width: 744px) {
    gap: 0;
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
    padding: 0 2vw;
  }
  @media only screen and (min-width: 1200px) {
    gap: 0;
    display: grid;
    grid-column-gap: 10px;
    grid-row-gap: 10px;
    grid-template-columns: repeat(6, 1fr);
    padding: 0 2vw;
  }
`
{
  /*   @media only screen and (min-width: 1200px) {
    grid-template-columns: repeat(12, 1fr);
  } */
}

export const query = graphql`
  query EditorialPageQuery {
    sanityPage(slug: { current: { eq: "illustration" } }) {
      ...pageFields
    }
  }
`

export default EditorialPage
